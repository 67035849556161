/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */


// react
import { useEffect, useRef } from "react";
import { useMaterialUIController } from "/context";

// next
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";

// fonts
import { robotoSlab } from "/utils/fonts";

import TypedJSText from "/examples/TypedJSText";

// MUI
const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });

// MUI Icons
const CollectionsRounded = dynamic(() => import("@mui/icons-material/CollectionsRounded"), { loading: () => null });
const PhoneEnabledRounded = dynamic(() => import('@mui/icons-material/PhoneEnabledRounded'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

// Parts
const FindUsOn = dynamic(() => import('/examples/FindUsOn'), { loading: () => null });
// const TypedJSText = dynamic(() => import('/examples/TypedJSText'), { loading: () => null });

function HeroBanner({ height, image, imageAlt, prefix1, prefix2, typedJSContent, subtitle, buttons, findUsOn }) {
  const [controller] = useMaterialUIController();
  const headerRef = useRef(null);

  useEffect(() => {
    let parallax = null;
    const start = async () => {
      if (headerRef.current !== null) {
        await import("rellax").then(u => parallax = new u.default(headerRef.current, { speed: -6 })
        )
      }
    };
    start();
    return () => {
      if (parallax !== undefined && parallax !== null) parallax.destroy()
    }
  }, [headerRef.current]);

  return (

    <div
      ref={headerRef}
      style={{
        minHeight: height,
        height: height,
        width: "100%",
        position: "relative",
        background: "#000",
        display: "grid",
        placeItems: "center"
      }}>
      <Image
        src={image}
        alt={imageAlt}
        fill
        sizes="100vw"
        style={{ objectFit: "cover", objectPosition: "center", maxWidth: "100%", maxHeight: "75vh" }}
        priority
      />
      <MDBox
        sx={{
          background: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}`,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
          width: "100%",
          height: height,
        }}
      />
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <>
          <MDTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              zIndex: 1,
              display: "block",
              minHeight: 80,
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
            className={robotoSlab.className}
          >
            {prefix1} <br />{prefix2} {controller.mobileView && <br />}
            {typedJSContent.length !== 0 && <TypedJSText typedJSContent={typedJSContent} />}
          </MDTypography>
          <MDTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
            {subtitle}
          </MDTypography>
          <MDBox display="flex">
            {buttons.includes("phone") && (
              <MDButton
                href="tel:1+631-424-5544"
                startIcon={<PhoneEnabledRounded color="success" />}
                variant="contained"
                fontWeight={300}
              >
                {controller.mobileView === false && "631-424-5544"}
                {controller.mobileView === true && "Call"}
              </MDButton>
            )}
            &nbsp;&nbsp;
            {buttons.includes("ourwork") && (
              <Link href="/our-work">
                <MDButton
                  startIcon={<CollectionsRounded color="info" />}
                  variant="contained"
                  fontWeight={300}
                >
                  Our Work
                </MDButton>
              </Link>
            )}
          </MDBox>
          {findUsOn === undefined || findUsOn === true && (
            <FindUsOn />
          )}
          </>
        </Grid>
      </Container>
    </div>
  );
}

export default HeroBanner;
