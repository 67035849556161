/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useEffect, useRef } from "react";

// typed-js
import Typed from 'typed.js';

function TypedJSText({ typedJSContent }) {
  const typedJSRef = useRef(null);

  useEffect(() => {
    let typedJS;
    if (typedJSContent.length !== 0) {
      typedJS = new Typed(typedJSRef.current, {
        strings: typedJSContent,
        typeSpeed: 90,
        backSpeed: 90,
        backDelay: 200,
        startDelay: 500,
        loop: false,
      });
    }
    return () => {
      if (typedJS !== null) typedJS.destroy();
    };
  }, []);

  return (
    <span ref={typedJSRef} />
  );
}

export default TypedJSText;
